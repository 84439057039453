import {useState, useEffect} from "react";
import {Outlet, useNavigate} from "react-router-dom";
import {RefreshToken} from "../api/authentication";

const checkAuth = async () => {
  await RefreshToken();
};

const ProtectedRoute = ({children}: any) => {
  const navigate = useNavigate();

  useEffect(() => {
    checkAuth().catch((err) => {
      navigate("/login");
    });
  }, []);

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
