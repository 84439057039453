import {useState, useEffect} from "react";

interface IWindowDimensions {
  width: number;
  height: number;
}

const useWindowDimensions = () => {
  const [windowSize, setWindowSize] = useState<IWindowDimensions>({
    width: 0,
    height: 0,
  });
  useEffect(() => {
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
};

export default useWindowDimensions;
