import {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Logout} from "../api/authentication";
import {CreateTicket} from "../api/tickets";
import Arrow from "../assets/images/icons/arrow_big.svg";
import Logo from "../assets/images/icons/logo.svg";
import LogoutIcon from "../assets/images/icons/logout.svg";
import ArrowIcon from "../assets/images/icons/arrow.svg";
import useWindowDimensions from "../hooks/useWindowDimensions";

const maxNumberOfTickets = 99;

const Home = () => {
  const navigate = useNavigate();
  const headerRef = useRef<HTMLDivElement>(null);
  const windowDimensions = useWindowDimensions();
  const [numberOfTickets, setNumberOfTickets] = useState<number>(0);
  const [generatedTickets, setGeneratedTickets] = useState<any[]>();
  const [initialTicket, setInitialTicket] = useState<number>(0);
  const [finalTicket, setFinalTicket] = useState<number>(3);
  const [currentTicket, setCurrentTicket] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [contentHeight, setContentHeight] = useState<{height: number}>({
    height: 0,
  });
  const [showLink, setShowLink] = useState<boolean>(false);

  useEffect(() => {
    if (windowDimensions.width === 0 || windowDimensions.height === 0) return;

    setContentHeight({
      height:
        windowDimensions.height -
        headerRef.current!.getBoundingClientRect().height,
    });
  }, [windowDimensions]);

  const addTicket = () => {
    const number = Number(numberOfTickets);
    if (number >= maxNumberOfTickets) return;
    setNumberOfTickets(number + 1);
  };

  const removeTicket = () => {
    if (numberOfTickets <= 0) {
      setNumberOfTickets(0);
    } else setNumberOfTickets(numberOfTickets - 1);
  };

  const generateTickets = async () => {
    if (numberOfTickets === 0) return;
    setIsLoading(true);
    setGeneratedTickets([]);
    setCurrentTicket(0);
    const res = await CreateTicket(numberOfTickets);
    setNumberOfTickets(0);
    setGeneratedTickets(res);
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  };

  const showNext = () => {
    setShowLink(false);
    if (currentTicket + 1 === generatedTickets?.length) return;
    setCurrentTicket(currentTicket + 1);
    // setInitialTicket(initialTicket + 1);
    // setFinalTicket(finalTicket + 1);
  };

  const showPrev = () => {
    setShowLink(false);
    if (currentTicket === 0) return;
    setCurrentTicket(currentTicket - 1);
    // setInitialTicket(initialTicket - 1);
    // setFinalTicket(finalTicket - 1);
  };

  const logoutUser = async () => {
    try {
      await Logout();
      navigate("/login");
    } catch (err) {
      console.log(err);
    }
  };

  const changeNumberOfTickets = (event: any) => {
    const result = event.target.value.replace(/\D/g, "").replace(/\b0+/g, "");
    if (result === "") {
      setNumberOfTickets(0);
    } else {
      if (Number(result) > maxNumberOfTickets) return;
      setNumberOfTickets(Number(result));
    }
  };

  return (
    <>
      {isLoading && <div className="loader">Loading...</div>}

      <div className={`min-h-screen ${isLoading ? "" : ""}`}>
        <div
          className="flex w-full justify-center items-center py-8"
          ref={headerRef}
        >
          {" "}
          <img src={Logo} width="200" height="70" />
          <button
            className="btn-close flex items-center justify-center absolute right-0 mr-8"
            onClick={logoutUser}
          >
            <span className="mr-4 text-sm">Cerrar sesión</span>
            <img src={LogoutIcon} width="15" className="ml-auto" />
          </button>
        </div>
        <div
          className={`flex items-center justify-center bg-mainBackground ${
            isLoading ? "opacity-0" : ""
          }`}
          style={contentHeight}
        >
          {generatedTickets && generatedTickets.length > 0 ? (
            <div className="flex flex-col items-center justify-start">
              <div className="flex flex-col items-center justify-center">
                {/* <div className="w-2/12 flex justify-end">
                  {" "}
                  {currentTicket > 0 && (
                    <img
                      src={generatedTickets[currentTicket - 1].qr}
                      width="150"
                      className="opacity-50"
                    />
                  )}
                </div> */}
                <div className="pb-16">
                  <button
                    className="flex items-center btn btn-main"
                    style={{width: 270}}
                    onClick={() => {
                      setGeneratedTickets([]);
                      setNumberOfTickets(0);
                    }}
                  >
                    <img src={ArrowIcon} alt="" width="50" className="mr-2" />
                    Volver a generar mas códigos{" "}
                  </button>
                </div>
                <div
                  className={`flex items-center justify-center p-1 mx-1 relative`}
                >
                  <div>
                    {" "}
                    <img
                      src={Arrow}
                      alt=""
                      className={`${
                        currentTicket === 0 ? "opacity-20" : "cursor-pointer"
                      }`}
                      onClick={showPrev}
                    ></img>
                  </div>
                  <div className="px-16 flex flex-col items-center justify-center">
                    {" "}
                    <span className="text-2xl text-mainRed font-bold">{`${
                      currentTicket + 1
                    }/${generatedTickets.length}`}</span>
                    <img src={generatedTickets[currentTicket].qr} />
                  </div>
                  <div>
                    {" "}
                    <img
                      src={Arrow}
                      alt=""
                      className={`rotate-180 ${
                        currentTicket + 1 === generatedTickets.length
                          ? "opacity-20"
                          : "cursor-pointer"
                      }`}
                      onClick={showNext}
                    ></img>
                  </div>{" "}
                </div>

                {/* <div className="w-2/12 flex justify-start">
                  {" "}
                  {currentTicket + 1 < generatedTickets.length && (
                    <img
                      src={generatedTickets[currentTicket + 1].qr}
                      width="150"
                      className="opacity-50"
                    />
                  )}
                </div> */}
              </div>
              <div className="flex flex-col items-center justify-center pt-4">
                {!showLink && (
                  <button
                    className="btn btn-red h-12"
                    onClick={() => setShowLink(!showLink)}
                  >
                    Obtener enlace
                  </button>
                )}

                <p
                  className={`h-12 text-center break-all text-lg ${
                    showLink ? "opacity-1" : "opacity-0"
                  }`}
                >
                  {generatedTickets[currentTicket].shortUrl}
                </p>
              </div>
            </div>
          ) : (
            <div className="flex flex-col justify-center items-center">
              {" "}
              <div className="py-8">
                <h1>Introduzca el número de códigos</h1>
              </div>
              <div className="flex border-2 border-gray-500 mb-16">
                <button
                  className="flex justify-center items-center bg-gray-200 text-gray-500 text-xl font-bold px-4 py-2  cursor-pointer"
                  onClick={removeTicket}
                >
                  -
                </button>
                <div className="flex justify-center items-center w-16">
                  <input
                    className="w-full h-full focus:outline-none text-center text-gray-500  text-2xl"
                    value={numberOfTickets}
                    onChange={changeNumberOfTickets}
                  ></input>
                </div>
                <button
                  className="flex justify-center items-center bg-gray-200 text-gray-500 font-bold text-xl px-4 py-2 cursor-pointer"
                  onClick={addTicket}
                >
                  +
                </button>
              </div>{" "}
              <div className="flex mt-8">
                <button
                  className={`${
                    numberOfTickets === 0 ? "opacity-20" : "cursor-pointer"
                  } btn btn-main flex `}
                  onClick={generateTickets}
                >
                  Generar{" "}
                  <img src={ArrowIcon} width="50" className="ml-4 rotate-180" />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Home;
