import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {DirectusLogin} from "../api/authentication";
import Logo from "../assets/images/icons/logo.svg";
import UserIcon from "../assets/images/icons/users.svg";

interface IFormData {
  email: string;
  password: string;
}

interface IErrorsData {
  email: string;
  password: string;
  login: string;
}

function validateForm(values: {email: string; password: string}) {
  const {email, password} = values;

  let errors: IErrorsData = {password: "", email: "", login: ""};
  if (!email) {
    errors.email = "Email address is required";
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = "Email address is invalid";
  }
  if (!password) {
    errors.password = "Password is required";
  } else if (password.length < 7) {
    errors.password = "Password must be 8 or more characters";
  }

  return errors;
}

const Login = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<IFormData>({
    email: "",
    password: "",
  } as IFormData);
  const [formErrors, setFormErrors] = useState<IErrorsData>({
    password: "",
    email: "",
    login: "",
  } as IErrorsData);

  const handleChange = (event: any) => {
    const {name, value} = event.target;
    setFormErrors((prev) => ({
      ...prev,
      [name]: "",
      login: "",
    }));
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const errors = validateForm({
      email: formData.email,
      password: formData.password,
    });

    if (errors.email || errors.password) setFormErrors(errors);
    else {
      try {
        const res = await DirectusLogin({
          email: formData.email,
          password: formData.password,
        });
        //console.log(res);
        navigate("/");
      } catch (err) {
        //console.log(err);
        setFormErrors((prevState) => ({
          ...prevState,
          login: "Usuario o contraseña no válidos",
        }));
      }
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      {" "}
      <div className="absolute top-0 w-full p-8 flex items-center">
        <img src={Logo} width="200" className="mr-auto" />
      </div>
      <div className="flex items-center pb-8">
        <img src={UserIcon} alt="" width="30" className="mr-4" />
        <h1 className="uppercase">Acceso a Empleados</h1>
      </div>{" "}
      {formErrors.login && (
        <div className="mb-4 px-4 py-2 bg-red-300 text-center">
          <span className="text-red-800 font-bold">
            Usuario o contraseña no válidos
          </span>
        </div>
      )}
      <form
        className="mb-4 md:flex md:flex-wrap md:justify-between py-8"
        method="post"
        onSubmit={handleSubmit}
      >
        {" "}
        <div className="field-group  md:w-full">
          <input
            className="field"
            type="text"
            name="email"
            id="email"
            placeholder="Email"
            value={formData.email || ""}
            onChange={handleChange}
          />
          <span className="text-red-500 text-sm px-3 py-1">
            {formErrors.email}
          </span>
        </div>
        <div className="field-group md:w-full">
          <input
            className="field"
            type="password"
            name="password"
            id="password"
            placeholder="Contraseña"
            value={formData.password || ""}
            onChange={handleChange}
          />
          <span className="text-red-500 text-sm px-3 py-1">
            {formErrors.password}
          </span>
        </div>
        <button className="btn btn-red mx-auto font-semibold" type="submit">
          Entrar
        </button>
      </form>
    </div>
  );
};
export default Login;
