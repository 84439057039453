export const CreateTicket = async (numberOfTickets: number) => {
  const arr: any[] = [];

  const requestOptions = {
    method: "GET",
    headers: {
      "x-api-key": process.env.REACT_APP_API_KEY || "",
      "Content-Type": "application/json",
      Authorization: `Bearer: ${localStorage.getItem("access_token")}`,
    },
  };

  for (let i = 0; i < numberOfTickets; i++) {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/generateCode`,
        requestOptions
      );
      const data = await res.json();
      arr.push(data);
    } catch (err) {
      //console.log(err);
    }
  }
  return arr;
};
