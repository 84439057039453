interface AuthenticationResponse {
  access_token: string;
  expires: number;
  refresh_token: string;
}

interface LoginCredentials {
  email: string;
  password: string;
  otp?: string;
}

/**
 * Authentication
 *
 *  - By default, all data in the system is off limits for unauthenticated users.
 *  - To gain access to protected data, you must include an access token with every request, or configure permissions for the public role.
 */

/**
 * Retrieve a Temporary Access Token
 * @param loginCredentials
 */
export async function DirectusLogin(loginCredentials: LoginCredentials) {
  const requestOptions = {
    method: "POST",
    headers: {
      "x-api-key": process.env.REACT_APP_API_KEY || "",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(loginCredentials),
  };

  try {
    const response = await fetch(
      `${process.env.REACT_APP_CMS_URL}/loginUser`,
      requestOptions
    );
    const data = await response.json();
    if (data.err) {
      throw new Error(data.err.message);
    }
    localStorage.setItem("access_token", data.data.access_token);
    localStorage.setItem("refresh_token", data.data.refresh_token);
  } catch (err) {
    //console.log("ERROR", err);
    throw new Error();
  }
}

export const RefreshToken = async () => {
  //console.log("Refresh", localStorage.getItem("refresh_token"));
  //if (!localStorage.getItem("refresh_token")) return;

  const requestOptions = {
    method: "POST",
    headers: {
      "x-api-key": process.env.REACT_APP_API_KEY || "",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      refresh_token: localStorage.getItem("refresh_token"),
    }),
  };

  try {
    const response = await fetch(
      `${process.env.REACT_APP_CMS_URL}/refreshToken`,
      requestOptions
    );
    const data = await response.json();
    if (data.err) {
      throw new Error(data.err.message);
    }
    //console.log(data.data);
    localStorage.setItem("access_token", data.data.access_token);
    localStorage.setItem("refresh_token", data.data.refresh_token);
  } catch (err) {
    //console.log(err);
    throw new Error();
  }
};

export const Logout = async () => {
  if (!localStorage.getItem("refresh_token")) return;
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("access_token");

  // const requestOptions = {
  //   method: "POST",
  //   headers: {"Content-Type": "application/json"},
  //   body: JSON.stringify({
  //     refresh_token: localStorage.getItem("refresh_token"),
  //   }),
  // };

  // await fetch(
  //   `${process.env.REACT_APP_CMS_URL}/auth/logout`,
  //   requestOptions
  // ).catch((err) => {
  //   return Promise.reject(err);
  // });
};
